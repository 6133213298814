import { request } from "../utils/axios.js";
export default {
  // 营销 => 分销管理
  getDealerList(params = {}) {
    return request("get", `/dealer/get_dealer_list`, params);
  },
  
  getDealerLowerList(params = {}) {
    return request("get", `/dealer/get_dealer_lower_list`, params);
  },

  addDealer(params = {}) {
    return request("get", `/dealer/add_dealer`, params);
  },

  deleteDealer(params = {}) {
    return request("get", `/dealer/delete_dealer`, params);
  },

  attachBuyer(params = {}) {
    return request("get", `/dealer/attach_buyer`, params);
  },

  deleteBuyer(params = {}) {
    return request("get", `/dealer/delete_buyer`, params);
  },

  getDealerOrderList(params = {}) {
    return request("get", `/dealer/get_dealer_order_list`, params);
  },

  initSetting(params = {}) {
    return request("get", `/dealer/init_setting`, params);
  },

  enableModule(params = {}) {
    return request("get", `/dealer/enable_module`, params);
  },

  saveSetting(params = {}) {
    return request("post", `/dealer/save_setting`, params);
  },

  getDealerWithdrawList(params = {}) {
    return request("get", `/dealer/get_dealer_withdraw_list`, params);
  },

  auditWithdraw(params = {}) {
    return request("get", `/dealer/audit_withdraw`, params);
  },

  applyWithdrawVip(params = {}) {
    return request("get", `/dealer/apply_withdraw_vip`, params);
  },

  execWithdrawVip(params = {}) {
    return request("get", `/dealer/exec_withdraw_vip`, params);
  },
};
